<template>
  <v-container class="grey lighten-5 min-h-100 px-0" fluid v-if="getRole">
    <v-row no-gutters v-if="getRole.id">
      <v-col md="11" cols="12" sm="8" xs="12" class="mx-auto">
        <v-card-title>
          Doctor
          <v-spacer></v-spacer>
          <v-btn
            v-if="getRole.id == superadmin"
            depressed
            elevation="1"
            color="primary"
            @click="goToAddDoctor"
            >Add Doctor</v-btn
          >
        </v-card-title>
        <vue-good-table
          :columns="headers"
          :rows="getDoctors"
          :line-numbers="true"
          styleClass="vgt-table striped"
          :pagination-options="{
            enabled: true,
            mode: 'records',
            perPage: 10,
            position: 'bottom',
            perPageDropdown: [10, 20, 50, 100],
            dropdownAllowAll: true,
            setCurrentPage: 1,
            jumpFirstOrLast: false,
            firstLabel: 'First',
            lastLabel: 'Last',
            nextLabel: 'Next',
            prevLabel: 'Prev',
            rowsPerPageLabel: 'Rows per page',
            ofLabel: 'of',
            pageLabel: 'page',
            allLabel: 'All',
            infoFn: (params) =>
              `Showing ${params.firstRecordOnPage} - ${params.lastRecordOnPage} Of ${params.totalRecords}  ||  Page ${params.currentPage} / ${params.totalPage}`,
          }"
          :search-options="{
            enabled: true,
            trigger: 'enter',
            placeholder: 'Search',
          }"
          @on-row-click="clickRow"
        >
          <div slot="emptystate">No Data Found.</div>
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'created'">
              {{ formatDate(props.row.created) }}
            </span>
          </template>
        </vue-good-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import { format } from 'date-fns';

export default {
  name: 'Doctor',
  data() {
    return {
      search: '',
      drawer: true,
      headers: [
        { label: 'ID', field: 'id', sortable: true, align: 'start' },
        { label: 'QR Code', field: 'qr_code', sortable: true },
        {
          label: 'Device Serial Number',
          field: 'device_serial_number',
          sortable: true,
        },
        { label: 'Check Code', field: 'check_code', sortable: true },
        { label: 'Name', field: 'name', sortable: true },
        { label: 'Email', field: 'email', sortable: true },
        { label: 'Phone No', field: 'phone_no', sortable: true },
        { label: 'Qualification', field: 'qualification', sortable: true },
        { label: 'Address', field: 'address', sortable: true },
        { label: 'Created DateTime', field: 'created', sortable: true },
      ],
    };
  },
  watch: {
    getRole(r) {
      if (r?.id == this.superadmin) {
      } else {
        this.$router.replace('/');
      }
    },
  },
  created() {
    if (this.getRole?.id == this.superadmin) {
      this.$store.dispatch('fetchDoctors', { self: this });
    } else {
      this.$router.replace('/');
    }
  },
  computed: {
    ...mapGetters(['getDoctors', 'getRole', 'getSearchText']),
    ...mapState({
      admin: (state) => state.admin,
      hradmin: (state) => state.hradmin,
      superadmin: (state) => state.superadmin,
    }),
  },
  methods: {
    ...mapMutations([]),
    goToAddDoctor() {
      this.$router.push('/add-doctor');
    },
    clickRow(params) {
      if (this.getRole.id == this.superadmin) {
        this.$router.push(`/edit-doctor/${params.row.id}`);
      }
    },
    formatDate(value) {
      if (value) {
        return format(value.seconds * 1000, 'dd MMM yyyy hh:mm a');
      }
    },
  },
  mounted() {
    if (this.getRole?.id != this.superadmin) {
      this.$router.push('/');
      return;
    }
    if (this.getDoctors && this.getDoctors.length) {
      this.doctors = this.getDoctors;
    } else {
      this.doctors = [];
    }
  },
};
</script>

<style></style>
