var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.getRole)?_c('v-container',{staticClass:"grey lighten-5 min-h-100 px-0",attrs:{"fluid":""}},[(_vm.getRole.id)?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"mx-auto",attrs:{"md":"11","cols":"12","sm":"8","xs":"12"}},[_c('v-card-title',[_vm._v(" Doctor "),_c('v-spacer'),(_vm.getRole.id == _vm.superadmin)?_c('v-btn',{attrs:{"depressed":"","elevation":"1","color":"primary"},on:{"click":_vm.goToAddDoctor}},[_vm._v("Add Doctor")]):_vm._e()],1),_c('vue-good-table',{attrs:{"columns":_vm.headers,"rows":_vm.getDoctors,"line-numbers":true,"styleClass":"vgt-table striped","pagination-options":{
          enabled: true,
          mode: 'records',
          perPage: 10,
          position: 'bottom',
          perPageDropdown: [10, 20, 50, 100],
          dropdownAllowAll: true,
          setCurrentPage: 1,
          jumpFirstOrLast: false,
          firstLabel: 'First',
          lastLabel: 'Last',
          nextLabel: 'Next',
          prevLabel: 'Prev',
          rowsPerPageLabel: 'Rows per page',
          ofLabel: 'of',
          pageLabel: 'page',
          allLabel: 'All',
          infoFn: (params) =>
            `Showing ${params.firstRecordOnPage} - ${params.lastRecordOnPage} Of ${params.totalRecords}  ||  Page ${params.currentPage} / ${params.totalPage}`,
        },"search-options":{
          enabled: true,
          trigger: 'enter',
          placeholder: 'Search',
        }},on:{"on-row-click":_vm.clickRow},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'created')?_c('span',[_vm._v(" "+_vm._s(_vm.formatDate(props.row.created))+" ")]):_vm._e()]}}],null,false,1762088647)},[_c('div',{attrs:{"slot":"emptystate"},slot:"emptystate"},[_vm._v("No Data Found.")])])],1)],1):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }